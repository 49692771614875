<header class="main-header">
  <nav class="main-navbar navbar navbar-expand-lg navbar-light shadow-2">
    <div class="lnav-box d-flex">
      <div class="logo-wrapper px-3 d-none d-md-flex">
        <img src="/assets/img/Logo.png" alt="Logo" class="logo" />
      </div>
      <div class="sidebar-toggler-wrapper ml-auto">
        <button class="p-collapsing-sidebar-toggler sidebar-toggler-secondary transition rounded-circle"
          data-collapsing="partially" data-collapsing-target="#adminNav" type="button">
          <span class="icon-menu-options"></span>
        </button>
      </div>
    </div>
    <div class="d-lg-block ml-0 mr-auto pl-4 div-width" id="AgentNoPadding">
      <span id="accountNoDashboard"></span>

    </div>
    <div class="pr-6 pl-6" id="TopBarMobile">
      <ul class="nav sec-nav navbar-nav flex-row">
        <li class="nav-item mobe" id="Agentlbl" style="display:none">
          <a href="#" class="my-3 mx-1 btn btn-glass btn-theme">Agent No : <span id="agentnolbl"></span></a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0);" title="Refresh"
            class="notf-link nav-link d-inline-flex align-items-center h-100 small-1 pl-2 pl-md-3"><span
              class="icon-refresh" style="font-size:20px"></span></a>
        </li>

        <li class="notf-item nav-item p-static p-md-relative dropdown">
          <a class="notf-link nav-link d-inline-flex align-items-center h-100 small-1 pl-2 pl-md-3" href="#"
            id="dropdownAdmin" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="p-relative d-inline-flex">
              <span class="badge badge-counter badge-warning l-1 notcount">0</span><span
                class="icon-bell lead-2"></span>
            </span>
          </a>

          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-sm-center dropdown-menu-right dropdown-menu-wh shadow-1 bc-t position-absolute mt-1"
            aria-labelledby="dropdownAdmin">
            <div class="dropdown-inner rounded-2">
              <div class="warning-gradient px-4 py-3 text-center">
                <h5 class="mb-0">Notifications (+<span class="notcount">0</span>)</h5>
              </div>

              <div class="pl-4 pr-3 py-5">
                <div class="dropdown-scrollbar" data-scrollbar="dropdown">
                  <ul class="list-unstyled notibind"></ul>
                </div>
              </div>
              <div class="dropdown-load-more text-center px-4 py-2">
                <a href="/Home/Notification"><span class="far fa-comment-alt mr-1"></span> load more</a>
              </div>
            </div>
          </div>
        </li>
        <li class="user-item nav-item dropdown">
          <a class="user-link nav-link d-inline-flex align-items-center h-100 small-1 pl-1 pl-sm-3 pr-0" href="#"
            id="dropdownAdmin_01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="user-avatar mr-sm-3" src="/assets/img/app/united-kingdom.svg" alt="language">
          </a>
          <div class="dropdown-menu dropdown-menu-right shadow-1 py-3 position-absolute mt-2"
            aria-labelledby="dropdownAdmin1">
            <a class="dropdown-item" href="/Culture/ChangeCulture"><img src="/assets/img/app/Kuwait.svg"
                style="width:30px;height:30px;margin-right:10px;margin-left:12px;"> اللغة العربية</a>
          </div>
        </li>
        <li class="user-item nav-item dropdown">
          <a class="user-link nav-link d-inline-flex align-items-center h-100 small-1 pl-1 pl-sm-3 pr-0" href="#"
            id="dropdownAdmin_02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img class="user-avatar rounded-circle mr-sm-3"
              src="https://www.clipartmax.com/png/middle/16-166560_log-clipart-user-profile-user-login-icon-png.png"
              alt="Profile Picture">
            <div class="d-none d-sm-block lh-1">
              <div class="lh-5">Ahmed Al Bayati</div>
              <span class="small-3"><i class="fas fa-circle text-warning small-5"></i> Online</span>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-right shadow-1 py-3 position-absolute mt-2"
            aria-labelledby="dropdownAdmin">
            <a class="dropdown-item" [routerLink]="['/lock']"><span class="icon-power-on-buton mr-2"></span>lock</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>

<main>
  <nav id="adminNav" class="main-sidebar p-collapsing-sidebar sidebar-fixed sidebar-left d-flex flex-column"
    style="background:rgb(2, 0, 34);">
    <div class="main-sidebar-inner" data-scrollbar="sidebar">
      <ul class="sidebar-nav sidebar-nav-light-hover list-unstyled text-unset small-3 fw-600 content-list">

        <li class="nav-item text-light transition" routerLinkActive="active-menu" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/']" id="SingleMenu" class="nav-link nav-link-border  collapserMain">
            <img src="/assets/icons/Dashboard.png" class="sidemenu-icons" style="margin-right: 11px" />
            <span class="p-collapsing-title">Dashboard</span>
          </a>
        </li>

        <li class="nav-item text-light transition" routerLinkActive="active-menu">
          <a [routerLink]="['/copy-trade/activate']" id="SingleMenu"
            class="sbr-collapse nav-link nav-link-border  collapserMain">
            <img src="/assets/icons/Copy-Trade.png" class="sidemenu-icons" />
            <span class="p-collapsing-title"> Copy-Trade</span>
          </a>
        </li>

        <li class="nav-item text-light transition" routerLinkActive="active-menu">
          <a [routerLink]="['/reversal-trading/activate']" id="SingleMenu"
            class="sbr-collapse nav-link nav-link-border  collapserMain">
            <img src="/assets/icons/ReversalTrading.png" class="sidemenu-icons" style="margin-right: 12px" />
            <span class="p-collapsing-title">Reversal Trading </span>
          </a>
        </li>

        <li class="nav-item text-light transition" routerLinkActive="active-menu">
          <a [routerLink]="['/coverage/activate']" id="SingleMenu" class="sbr-collapse nav-link nav-link-border  collapserMain">
            <img src="/assets/icons/Coverage.png" class="sidemenu-icons" />
            <span class="p-collapsing-title"> Coverage</span>
          </a>
        </li>

        <li class="nav-item text-light transition" routerLinkActive="active-menu">
          <a [routerLink]="['/master-accounts/new-account']" id="SingleMenu" class="sbr-collapse nav-link nav-link-border  collapserMain">
            <img src="/assets/icons/CoverageAccount.png" class="sidemenu-icons" />
            <span class="p-collapsing-title"> Master Accounts</span>
          </a>
        </li>

        <li class="nav-item text-light transition" routerLinkActive="active-menu">
          <a [routerLink]="['/strategies/new-strategy']" id="SingleMenu" class="sbr-collapse nav-link nav-link-border  collapserMain">
            <img src="/assets/icons/Strategies.png" class="sidemenu-icons" />
            <span class="p-collapsing-title"> Strategies</span>
          </a>
        </li>

        <!-- <li class="nav-item text-light transition" routerLinkActive="active-menu">
          <a [routerLink]="['/trading-accounts']" id="SingleMenu" class="sbr-collapse nav-link nav-link-border  collapserMain">
            <img src="/assets/img/MenuIcons/Trader Analysis.png" class="sidemenu-icons" />
            <span class="p-collapsing-title"> Trading Accounts</span>
          </a>
        </li>

        <li class="nav-item text-light transition" routerLinkActive="active-menu">
          <a [routerLink]="['/symbol']" id="SingleMenu" class="sbr-collapse nav-link nav-link-border  collapserMain">
            <img src="/assets/img/MenuIcons/Trader Analysis.png" class="sidemenu-icons" />
            <span class="p-collapsing-title"> Symbol Mapping</span>
          </a>
        </li> -->

        <li class="nav-item text-light transition" routerLinkActive="active-menu">
          <a [routerLink]="['/MyList']" id="SingleMenu" class="sbr-collapse nav-link nav-link-border  collapserMain">
            <img src="/assets/icons/MyList.png" class="sidemenu-icons" />
            <span class="p-collapsing-title"> My List</span>
          </a>
        </li>

      </ul>
    </div>
  </nav>

  <div class="main-container">
    <div class="overflow-hidden">
      <router-outlet></router-outlet>
    </div>

    <footer id="footer" class="footer">
      <div class="container-fluid">
        <div class="row gutters-y align-items-center">
          <div class="col-md-6 col-lg-5">
            <div class="px-3 fw-400">
              All rights reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</main>
