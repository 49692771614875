import { AfterViewInit, Component } from '@angular/core';
declare function sidebar(): any;
declare function menucollepse(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'demo-aximus'
  public today: number = Date.now();

  ngAfterViewInit() {
    sidebar();
    menucollepse();
  }

  
}
